import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Home from "./Home.js";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Route exact path="/" component={Home} />
    </BrowserRouter>
  );
}

const GlobalStyle = createGlobalStyle`
  /* override browser default */
  html, ul {
    margin: 0;
    padding: 0;
  }
  /* include border and padding in element width and height */
  * {
    box-sizing: border-box;
  }
  body {
    padding: 0;
    margin: 0;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 200;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h1, h2 {
    margin: 0;
    padding: 0;
  }

  footer {
    div {
      position: fixed;
      bottom: 2vh;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 0.75rem;
      color: #DEDEDE;
      background: transparent;
    }
  }
`;

export default App;
