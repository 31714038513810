import React from "react";
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

function ProjectItem(props) {
  return (
    <Container>
      <Fade>
        <Image>
          <img src={props.project.image} alt="{props.project.title}" />
        </Image>
        <Info>
          <div>
            <div>
              <div className="title">{props.project.title}</div>
            </div>
            <div className="medium big-margin">
              {props.project.desc.split("\n").map((i, key) => {
                return <p key={key}>{i}</p>;
              })}
            </div>
            <div className="medium small-margin">
              {props.project.desc_k.split("\n").map((i, key) => {
                return <p key={key}>{i}</p>;
              })}
            </div>
            <div className="medium big-margin">
              <b style={{ fontWeight: 400 }}>{props.project.skills}</b>
            </div>
            <div className="medium normal-margin">
              <b style={{ fontWeight: 300 }}>{props.project.scope}</b>
            </div>
            <div className="small">{props.project.date}</div>
            <div className="links medium normal-margin">
              {props.project.link.map((link, i) => {
                return (
                  <span>
                    {i > 0 ? "  " : ""}
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={i}
                    >
                      {link.text}
                    </a>
                  </span>
                );
              })}
            </div>
          </div>
        </Info>
      </Fade>
    </Container>
  );
}

const Container = styled.li`
  display: flex;
  align-items: center;
  margin: 30vh auto;
  padding: 0;
  color: black;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: 15vh auto;
  }
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 2rem;

  img {
    margin: auto auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 2rem;

  .title {
    font-size: 2.3rem;
    font-weight: bold;
  }
  .links {
    font-size: 0.9rem;
    color: #1627c7;

    a {
      font-weight: bold;
      margin: 0 0.5em;
      :hover {
        text-decoration: underline;
      }
    }
  }

  .small {
    margin-top: 2%;
    font-size: 0.7rem;
  }
  .medium {
    font-size: 0.9rem;
    line-height: 0.5rem;
  }
  .big-margin {
    margin-top: 3rem;
  }
  .normal-margin {
    margin-top: 2.5rem;
  }
  .small-margin {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin: 5vh auto 3% auto;

    .big-margin {
      margin-top: 9%;
    }
    .normal-margin {
      margin-top: 4%;
    }

    div {
      width: 90vw;
      white-space: normal;
    }

    .medium {
      line-height: 1rem;
    }

    p {
      width: 90vw;
      white-space: normal;
      line-height: 1rem;
      padding: 0;
      margin: 0 auto;
      margin-top: 1%;
      word-break: keep-all;
    }
  }
`;


export default ProjectItem;