import React from 'react';
import styled from 'styled-components';
import ProjectItem from './components/ProjectItem';
import projects from './data/projects'
import Fade from 'react-reveal/Fade';

function Projects() {
  const scrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'})
  return (
    <Container>
      <List>
        { 
          projects.map((p, k) => <ProjectItem project={p} key={k} />)
        }
      </List>
    <Fade bottom>
      <Arrow onClick={scrollToTop}><span>TOP</span></Arrow>
      </Fade>
    </Container>
    
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 800px) {
    margin-top: 20%;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  display: block;
  margin: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-style: none;
  -webkit-overflow-scrolling: touch;

  @media only screen and (min-width: 801px) {
    li {
      text-align: left;
    }
    li:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
`;

const Arrow = styled.span`
  display: inline-block;
  margin-left: 50%;
  margin-bottom: 7vh;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -.75rem;
  border-top: 1px solid black;
  border-right: 1px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    border: none;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-left: -1.5rem;
    span {
      opacity: 1;
    }
  }

  span {
    display: inline-block;
    width: 2rem;
    opacity: 0;
    text-align: center;
  }
`
 
export default Projects;