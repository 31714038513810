import driveImg from '../images/p_drive.png';
import heyzooImg from '../images/p_heyzoo.png';
import ocadImg from '../images/p_ocad.png';
import valoyImg from '../images/p_valoy.png';
import winterImg from '../images/p_winter.png';
import wtsImg from "../images/p_whattosay_1.png";
import wizlabImg from "../images/p_wizlab.png";
import bangclImg from "../images/p_bangcl.png";

const projects = [
  {
    title: "뭐라고할까",
    link: [{ text: "Open Web", url: "https://whattosay.kr" }],
    desc: "Provides contact templates\nthat can be used in a variety of situations, including business.",
    desc_k:
      "다양한 상황, 특히 비즈니스 상황에서 사용할 수 있는\n" +
      "이메일, 문자 등에 활용할 수 있는 커뮤니케이션 템플릿을 제공합니다.",
    skills:
      "React, Styled-Components, Firestore, Firebase Auth/Hosting/Cloud Function",
    scope: "Development 100%, Planning 50%",
    date: "2020.05 - 2020.08, 2021.10 업데이트",
    image: wtsImg
  },
  {
    title: "Bangcl",
    link: [{ text: "Open Web", url: "https://bangcl.co.kr" }],
    desc: "An online learning platform(LMS)\nwhere instructors can open and manage courses,\nand students can register for courses and submit assignments.",
    desc_k:
      "강사는 수업 개설 및 관리, 학생은 수강 신청 및 학습 활동을 할 수 있는\n온라인 수업 플랫폼(LMS)입니다.\n사용자 및 관리자용 프론트엔드 개발과 API 개발에 참여했습니다.",
    skills: "React, Redux, SCSS, Sequelize",
    scope: "Development 15%",
    date: "2021.01 - 2021.05",
    image: bangclImg
  },
  {
    title: "WizLab 3D Game IDE",
    link: [
      {
        text: "Open Web",
        url: "https://wizlab.net/builder"
      }
    ],
    desc: "A beta version of a web-based 3D game development tool.\nProvides real-time code sync, own APIs, 3D assets, game test and deploy and more.",
    desc_k:
      "웹 기반의 3D 게임 개발 툴 베타 버전 개발에 참여했습니다.\n" +
      "실시간 코드 동기화, 자체 API와 3D 에셋, 게임 실행 및 배포 기능 등을 제공합니다.",
    skills: "Babylon.js, React, Redux, SCSS",
    scope: "Development 50%",
    date: "2020.11 - 2020.12",
    image: wizlabImg
  },
  {
    title: "One Change A Day",
    link: [
      {
        text: "View on the App Store",
        url: "https://apps.apple.com/app/one-change-a-day/id1505195374"
      }
    ],
    desc: "An app that helps to be a better person everyday.",
    desc_k:
      "하루에 한 가지씩 개선할 것을 등록하는 앱입니다.\n" +
      "등록한 내용은 수정, 삭제 가능하고, 현재까지 등록한 내용을 모아볼 수 있습니다.\n" +
      "로그인 하면 데이터가 실시간 동기화 됩니다.",
    skills: "iOS, Swift, Storyboard, Firebase Auth, Firestore, AppStore",
    scope: "Planning, Design, Development 100%",
    date: "2020.01 - 2020.04",
    image: ocadImg
  },
  {
    title: "HeyZoo AR",
    link: [
      {
        text: "View on the App Store",
        url: "https://apps.apple.com/app/heyzoo-ar/id1477365767"
      },
      {
        text: "View on the Google Play",
        url: "https://play.google.com/store/apps/details?id=net.valoy.heyzoo"
      }
    ],
    desc: "AR forest making game for kids.",
    desc_k:
      "아이들을 위한 증강현실 숲 꾸미기 게임입니다.\nAR Foundation을 사용한 평면 인식을 통해 " +
      "3D 숲을 꾸미고 동물을 촬영해 수집합니다.\n실제 동물 정보가 담긴 팝업이 있으며,\n플레이 타임 제한 등 " +
      "부모님 통제권을 위한 기능들이 있습니다.",
    skills: "Unity 3D, C#, AR Foundation, AdMob, IAP, GooglePlay, AppStore",
    scope: "Development 100%, Planning 50%, Project Managing",
    date: "2019.03 - 2019.09",
    image: heyzooImg
  },
  {
    title: "HeyZoo AR - Winter Edition",
    link: [
      {
        text: "View on the App Store",
        url: "https://apps.apple.com/app/heyzoo-ar-winter-edition/id1491015774"
      },
      {
        text: "View on the Google Play",
        url: "https://play.google.com/store/apps/details?id=net.valoy.w.heyzoo"
      }
    ],
    desc: "Decorate a snowy winter forest and meet animals wearing Santa hats.",
    desc_k:
      "HeyZoo AR의 겨울 에디션으로,\n눈 내리는 겨울 숲을 꾸미며 산타 모자를 쓴 동물들을 만날 수 있습니다.",
    skills: "Unity 3D, C#, AR Foundation, GooglePlay, AppStore",
    scope: "Development 100%, Planning 50%, Project Managing",
    date: "2019.10 - 2019.12",
    image: winterImg
  },
  // {
  //   title: "Tech Blog",
  //   link: [{ text: "Open Web", url: "https://jisoo-rlzr.github.io/" }],
  //   desc: "My personal technical blog built with Hugo.",
  //   desc_k: "개인 기술 블로그로, Hugo 테마를 커스터마이징 하여 개발했습니다.",
  //   skills: "Hugo, GitHub Pages",
  //   scope: "Development 100%, Theme Customizing",
  //   date: "2020.01",
  //   image: blogImg
  // },
  {
    title: "Valoy Landing Page",
    link: [{ text: "Open Web", url: "https://valoy.net" }],
    desc: "A landing page for Valoy company and products.\nIntroduction, app download link, and email section are included.",
    desc_k:
      "Valoy 회사 소개 겸 출시 제품에 대한 랜딩페이지 입니다.\n소개, 다운로드 링크, 이메일 전송 부분이 " +
      "원페이지로 구성되어 있습니다.",
    skills: "Bootstrap, HTML, CSS, Firebase Hosting, Firebase Cloud Function",
    scope: "Development 100%, Bootstrap Customizing",
    date: "2019.08",
    image: valoyImg
  },
  {
    title: "Melon",
    link: [
      {
        text: "View on the Google Play",
        url: "https://play.google.com/store/apps/details?id=com.iloen.melon"
      }
    ],
    desc: "Participated in the development of the Android app 'Melon',\na music streaming app in Korea with over 5 million paid subscribers.",
    desc_k:
      "안드로이드 개발 팀에 소속되어\n서버, 기획, 디자인 팀 등과 협업하며 " +
      "멜론 앱을 개발했습니다.",
    skills: "Android, Android Wear, Java, Git, Jira, MirrorLink",
    scope: "Development 10%",
    date: "2015.05 - 2017.09",
    image: driveImg
  }
];

export default projects;