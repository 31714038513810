import React, { useRef } from 'react';
import styled from 'styled-components';
import Projects from './Projects.js';

function Home() {
  const headRef = useRef();
  const scrollToProjects = () =>
    window.scrollTo({
      top: headRef.current.getBoundingClientRect().bottom,
      behavior: "smooth"
    });

  return (
    <Container>
      <Head ref={headRef}>
        <MenuContainer>
          <A href="https://cutt.ly/1ys5Z6c">BLOG</A>
          <div>
            CONTACT
            <ContactContainer>
              <A href="mailto:contact@jisoo.codes">Email</A>
              <A href="https://linkedin.com/in/jisoo-kim-profile">LinkedIn</A>
              {/* <A href="https://github.com/jisoo-rlzr">GitHub</A> */}
            </ContactContainer>
          </div>
        </MenuContainer>
        <Name>
          <div>JISOO KIM</div>
          <div>수코드</div>
        </Name>
        <About>
          Realize Creative Ideas Through
          <br />
          Software Development
        </About>
        <Footer>&copy; Jisoo Kim 2020 All Rights Reserved.</Footer>
        <Arrow onClick={scrollToProjects}>
          <span>PROJECTS</span>
        </Arrow>
      </Head>
      <Projects />
    </Container>
  );
}

const A = styled.a.attrs({
  target: "_blank"
})``;

const Container = styled.div`
  background: transparent;
  color: black;
`;

const Head = styled.div`
  position: relative;
  height: 100vh;
  background: white;
`;

const Name = styled.span`
  position: absolute;
  display: block;
  top: 21vh;
  left: 18vw;
  font-size: 3rem;
  font-weight: 300;

  div:nth-child(2) {
    margin-top: 0.1em;
    color: #1627c7;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

const About = styled(Name)`
  position: absolute;
  top: inherit;
  bottom: 32vh;
  right: 18vw;
  text-align: right;
  line-height: 1.3em;
  font-weight: 100;
  font-size: 3.5rem;
  @media only screen and (max-width: 800px) {
    bottom: 18vh;
    line-height: 1.3em;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 8vh;
  right: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: top;
  font-size: 0.875rem;
  font-weight: 300;

  a {
    margin-right: 4.9em;
    :hover {
      text-decoration: underline;
    }
  }

  div {
    position: relative;
    height: 24px;
    cursor: pointer;
    overflow: hidden;
    :hover {
      overflow: visible;
      div {
        height: 7.7em;
        a {
          animation: enter 0.15s ease-out forwards;
        }
        a:nth-child(2) {
          animation-delay: 0.1s;
        }
        a:nth-child(3) {
          animation-delay: 0.2s;
        }
      }
    }
  }
`;

const ContactContainer = styled.div`
  position: absolute;
  padding-top: 1.5em;
  left: 0;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.48);

  a {
    display: block;
    margin-bottom: 1em;
    margin-right: 0;
    opacity: 0;
  }

  @keyframes enter {
    from {
      opacity: 0;
      transform: scaleY(0.98) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 7vh;
  left: 4vw;
  text-align: center;
  font-size: 0.75rem;
  color: #dedede;
  background: transparent;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const Arrow = styled.span`
  position: absolute;
  // display: inline-block;
  left: 50%;
  bottom: 7vh;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -.75rem;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    border: none;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-left: -2.5rem;
    span {
      opacity: 1;
    }
  }

  span {
    display: inline-block;
    width: 5rem;
    opacity: 0;
    text-align: center;
  }
`
 
export default Home;